<template>
  <div>
    <Form label-colon inline>
      <FormItem label="设备型号" :label-width="100">
        <Select v-model="search.device_type_id" placeholder="请选择型号搜索，可填写" style="width: 200px;" filterable>
          <Option v-for="(item,index) in device_type_data" :key="index" :value="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem :label-width="0">
        <Button type="primary" @click="changePage(1)">搜索</Button>
      </FormItem>
      <FormItem :label-width="0">
        <Button @click="resetSearch">重置</Button>
      </FormItem>
      <FormItem :label-width="0">
        <Button @click="openEditModal()">关联操作</Button>
      </FormItem>
    </Form>
    <Table :columns="columns" :data="data" :loading="loading" stripe>
      <template #deviceOperation="{row}">
        <Select v-model="row.operationIds" multiple transfer :max-tag-count="2">
          <Option v-for="item in row.device_operations" :value="item.id" :key="item.id" disabled>{{ item.name }}</Option>
        </Select>
      </template>
      <template #deviceParameter="{row}">
        <Select v-model="row.parametersIds" multiple transfer :max-tag-count="2">
          <Option v-for="item in row.device_parameters" :value="item.id" :key="item.id" disabled>{{ item.name }}</Option>
        </Select>
      </template>
      <template #operation="{row}">
        <Button type="primary" @click="openEditModal(row)">编辑</Button>
        <Button style="margin-left: 12px;" @click="deleteData(row)">删除</Button>
      </template>
    </Table>

    <div class="ser-page" style="text-align: right;">
      <Page
          v-model="search.page"
          :total="search.total"
          :page-size="search.pageSize"
          :page-size-opts="[15,30,50]"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-total show-sizer />
    </div>

    <Modal v-model="editModal.show" :title="editModal.title" width="1000" class-name="vertical-center-modal" @on-cancel="cancelEditModal">
      <Form ref="editModalForm" :model="editModal.formData" :rules="editModal.rules" :label-width="120" label-colon>
        <FormItem label="设备型号" prop="device_type_id">
          <Select v-model="editModal.formData.device_type_id" placeholder="请选择型号，可填写" style="width: 100%;" filterable multiple>
            <Option v-for="(item,index) in device_type_data" :key="index" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="可用的功能" prop="device_operation_id">
          <Select v-model="editModal.formData.device_operation_id" placeholder="请选择功能，可填写" style="width: 100%;" class="modal-select" filterable multiple>
            <Option v-for="(item,index) in device_operation_data" :key="index" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="可设置的参数" prop="parameter_type_id">
          <Select v-model="editModal.formData.parameter_type_id" placeholder="请选择设置参数，可填写" placement="top" style="width: 100%;" class="modal-select" filterable multiple transfer @on-select="selectOption">
            <Option v-for="(item,index) in parameter_type_data" :key="index" :value="item.id">{{ item.name }}</Option>
          </Select>
          <Card title="参数配置" style="margin-top: 12px;" v-if="editModal.formData.parameter_config.length">
            <Row class-name="row-line" v-for="(item, index) in editModal.formData.parameter_config" :key="index">
              <Col>
                <FormItem :label="item.name" />
              </Col>
              <Col flex="auto">
                <FormItem label="最大值" :label-width="80" v-if="item.config.max !== undefined">
                  <InputNumber v-model="item.config.max" />
                </FormItem>
              </Col>
              <Col flex="auto">
                <FormItem label="最小值" :label-width="80" v-if="item.config.min !== undefined">
                  <InputNumber v-model="item.config.min" />
                </FormItem>
              </Col>
            </Row>
          </Card>
        </FormItem>
      </Form>
      <template #footer>
        <Button @click="cancelEditModal">取消</Button>
        <Button type="primary" @click="confirmEditModal">确认</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
  let _this;
  export default {
    data() {
      return {
        search: {
          device_type_id: null,
          page: 1,
          pageSize: 15,
          total: 0
        },
        loading: false,
        data: [],
        columns: [
          {title: '设备型号', key: 'device_type_name'},
          {title: '设备功能', slot: 'deviceOperation'},
          {title: '设备参数', slot: 'deviceParameter'},
          {title: '更新时间', key: 'create_time'},
          {title: '操作', slot: 'operation', width: 240}
        ],
        device_type_data: [],
        device_operation_data: [],
        parameter_type_data: [],
        editModal: {
          show: false,
          title: '新增设备操作',
          formData: {
            device_type_id: [],
            device_operation_id: [],
            parameter_type_id: [],
            parameter_config: []
          },
          rules: {
            device_type_id: [
              { type: 'array', required: true, message: '请选择设备型号id', trigger: 'change' }
            ],
            device_operation_id: [
              { type: 'array', required: true, message: '请选择设备该有的功能', trigger: 'change' }
            ],
            parameter_type_id: [
              { type: 'array', required: true, message: '请选择设备该有的参数', trigger: 'change' }
            ],
          }
        }
      }
    },
    created() {
      _this = this;
    },
    methods: {
      init() {
        this.loading = true;
        this.requestApi('/adm/get_device_type_operation', _this.search).then(res => {
          if(res.status === 1) {
            _this.search.total = res.data.operation.total;
            _this.data = res.data.operation.data;
            _this.device_type_data = res.data.deviceType;
            _this.device_operation_data = res.data.deviceOperation;
            _this.parameter_type_data = res.data.parameterType;
            _this.loading = false;
          } else {
            _this.alertErr(res.msg)
          }
        })
      },
      changePage(page) {
        this.search.page = page;
        this.init()
      },
      changePageSize(size) {
        this.search.page = 1;
        this.search.pageSize = size;
        this.init()
      },
      // 重置搜索条件
      resetSearch() {
        this.search.page = 1;
        this.search.device_type_id = null;
        this.init()
      },
      openEditModal(row) {
        this.editModal.show = true;
        if (row) {
          this.editModal.title = '编辑设备操作';
          this.editModal.formData.device_type_id = row.device_type_id;
          this.editModal.formData.device_operation_id = row.operationIds;
          this.editModal.formData.parameter_type_id = row.parametersIds;
          this.editModal.formData.parameter_config = row.parameterConfig;
        }
      },
      // 添加设备操作
      selectOption(item) {
        for (let index = 0; index < this.parameter_type_data.length; index++) {
          const element = this.parameter_type_data[index];
          if (element.config) {
            if (element.id === item.value) {
              let ConfigIndex = -1;
              for (let index2 = 0; index2 < this.editModal.formData.parameter_config.length; index2++) {
                const element2 = this.editModal.formData.parameter_config[index2];
                if (element2.id === item.value) {
                  ConfigIndex = index2;
                  break;
                }
              }
              if (ConfigIndex === -1) {
                this.editModal.formData.parameter_config.push({
                  id: item.value,
                  name: element.name,
                  config: element.config
                });
              } else {
                this.editModal.formData.parameter_config.splice(ConfigIndex, 1);
              }
              break;
            }
          }

        }
        this.editModal.formData.parameter_config.push()
      },
      // 确认编辑
      confirmEditModal() {
        this.$refs.editModalForm.validate((valid) => {
          if (valid) {
            this.requestApi('/adm/edit_device_type_operation', this.editModal.formData).then(res => {
              if (res.status === 1) {
                _this.alertSucc('编辑成功');
                _this.cancelEditModal();
                _this.init();
              } else {
                _this.alertErr(res.msg);
              }
            })
          } else {
            _this.alertErr('请填写完整信息');
          }
        });
      },
      // 取消编辑
      cancelEditModal() {
        
        this.$refs.editModalForm.resetFields();
        this.editModal.formData.parameter_config = [];
        this.editModal.show = false;
      },
      // 删除
      deleteData(row) {
        this.$Modal.confirm({
          title: '提示',
          content: '确定删除该条数据吗？',
          onOk: () => {
            this.requestApi('/adm/delete_device_type_operation', {device_type_id: row.device_type_id}).then(res => {
              if (res.status === 1) {
                _this.alertSucc('删除成功');
                _this.init();
              } else {
                _this.alertErr(res.msg);
              }
            })
          }
        });
      }
    }
  }
</script>

<style scoped>
  /deep/ .vertical-center-modal .ivu-modal-body {
    height: calc(100vh - 300px);
    overflow-y: auto;
  }

  /deep/ .modal-select .ivu-select-selection {
    min-height: 96px;
  }

  .row-line {
    margin-bottom: 12px;
    border-bottom: 1px solid #dcdee2;
  }
</style>
