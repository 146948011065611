<template>
	<div class="frame_class">
		<Tabs v-model="t_name" @on-click="switch_init">
      <TabPane label="新版" name="e_new_operation">
        <new-operation ref="NewOperation"></new-operation>
      </TabPane>
			<TabPane label="设备操作" name="e_operation">
				<div class="ser-form ser-form-centered">
					<div class="form-item-label margin-right">筛选搜索</div>
					<div class="form-item-content">
						<RadioGroup v-model="serData.status">
							<Radio :label="0">隐藏</Radio>
							<Radio :label="1">显示</Radio>
						</RadioGroup>
						<Input class="margin-right" v-model="serData.value" placeholder="输入名称搜索" style="width: 200px" />
						<Select v-model="serData.model_id" style="width:200px" class="margin-right" :clearable="true"
							placeholder="请选择型号">
							<Option v-for="item in model_data" :value="item.id" :key="item.id">{{ item.name }}
							</Option>
						</Select>
						<Select v-model="serData.class_type" style="width:200px" class="margin-right" :clearable="true"
							placeholder="请选择操作类型">
							<Option v-for="(item,index) in class_data" :value="index" :key="index">{{ item }}
							</Option>
						</Select>
						<Button type="primary" @click="init()" class="margin-right">搜索</Button>
						<Button type="primary" class="margin-right" @click="typeModal()">关联</Button>
						<Button type="primary" icon="md-add" @click="openModal()">新增设备操作</Button>
					</div>
				</div>
				<div class="table-list">
					<Table :columns="columns" :data="data" :loading="loading">
						<template slot-scope="{row,index}" slot="operation">
							<Button @click="openModal(index)" style="margin-right: 8px;">编辑</Button>
							<Button @click="del(row.id)">删除</Button>
						</template>
						<template slot-scope="{row}" slot="image">
							<Tooltip :content="row.image" placement="top" :transfer="true" :events-enabled="true"
								max-width="200">
								<div class="text-ellipsis">{{ row.image }}</div>
							</Tooltip>
						</template>
						<template slot-scope="{row}" slot="all_model_name">
							<Tooltip :content="row.all_model_name" placement="top" :transfer="true"
								:events-enabled="true" max-width="200">
								<div class="text-ellipsis">{{ row.all_model_name }}</div>
							</Tooltip>
						</template>
					</Table>
				</div>
				<div class="ser-page">
					<Page class-name="page-r" :total="total" :page-size="serData.pageSize" :page-size-opts="[15,30,50]"
						@on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
				</div>
			</TabPane>
			<TabPane label="设备参数操作配置" name="e_operation_parameters">
				<oper ref="oper"></oper>
			</TabPane>
			<TabPane label="设备参数" name="e_parameter_operation">
				<paop ref="paop"></paop>
			</TabPane>
		</Tabs>

		<Modal v-model="device_modal" :title="formData.id?'编辑设备操作':'添加设备操作'">
			<Form ref="formValidate" :model="formData" :rules="ruleValidate" :label-width="100">
				<FormItem label="操作名称" prop="name">
					<Input v-model="formData.name" placeholder="请输入操作名称"></Input>
				</FormItem>
				<FormItem label="图片路径" prop="image">
					<Input v-model="formData.image" placeholder="请输入图片路径"></Input>
				</FormItem>
				<FormItem label="操作类型" prop="link">
					<RadioGroup v-model="formData.action_type">
						<Radio :label="0">链接</Radio>
						<Radio :label="1">方法</Radio>
					</RadioGroup>
					<Input v-model="formData.link" placeholder="请输入链接或方法"></Input>
				</FormItem>
				<FormItem label="操作项分类" prop="class_type">
					<RadioGroup v-model="formData.class_type">
						<Radio :label="0">健康守护</Radio>
						<Radio :label="1">安全守护</Radio>
						<Radio :label="2">设备控制</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="背景颜色" prop="bg_color">
					<Input v-model="formData.bg_color" placeholder="请输入背景颜色"></Input>
					<div class="color_css">输入格式:rgb(111,111,111),rgb(111,111,111)</div>
				</FormItem>
				<FormItem label="参数单位" prop="company">
					<Input v-model="formData.company" placeholder="请输入参数单位"></Input>
				</FormItem>
				<FormItem label="是否显示" prop="status">
					<RadioGroup v-model="formData.status">
						<Radio :label="0">否</Radio>
						<Radio :label="1">是</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="save">保存</Button>
				<Button @click="device_modal=false">取消</Button>
			</div>
		</Modal>

		<Modal v-model="device_type" title="关联">
			<Form :model="type_data" :label-width="60">
				<FormItem label="选择">
					<RadioGroup v-model="type_data.op_mo" @on-change="choice">
						<Radio label="0"><span>操作</span></Radio>
						<Radio label="1"><span>类型</span></Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="操作">
					<Select v-model="type_data.id" :multiple="mo_op_data.op_true" :label-in-value="true"
						:disabled="mo_op_data.op_disabled" @on-change="type_data.op_mo == 0 ? select(0) : ''">
						<Option v-for="(item,index) in op_data" :value="item.id" :key="index">
							{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="类型">
					<Select v-model="type_data.model_id" :multiple="mo_op_data.mo_true"
						:disabled="mo_op_data.mo_disabled" @on-change="type_data.op_mo == 1 ? select(1) : ''">
						<Option v-for="(item,index) in model_data" :value="item.id" :key="index">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_type">保存</Button>
				<Button @click="device_type=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import paop from '../../../components/device/parameter_operation';
	import oper from '../../../components/device/operation_parameters';
  import NewOperation from '../../../components/device/new_operation';
	import {
		init
	} from 'echarts';
	export default {
		components: {
			paop,
			oper,
      NewOperation
		},
		data() {
			return {
				t_name: 'e_new_operation',
				loading: false,
				total: 0,
				serData: {
					page: 1,
					pageSize: 15,
					model_id: null,
					class_type: null,
					status: 1,
					value: null,
				},
				device_modal: false,
				formData: {
					name: null,
					image: null,
					action_type: 0,
					link: null,
					class_type: 0,
					bg_color: null,
					company: null,
					status: 1,
				},
				ruleValidate: {
					name: [{
						required: true,
						type: 'string',
						message: '请输入名称',
						trigger: 'blur'
					}],
					image: [{
						required: true,
						type: 'string',
						message: '请输入图片路径',
						trigger: 'blur'
					}],
					link: [{
						required: true,
						type: 'string',
						message: '请输入链接或方法',
						trigger: 'blur'
					}]
				},
				model_data: [], //类型
				op_data: [], //操作配置
				columns: [{
						title: 'ID',
						key: 'id',
					},
					{
						title: '名称',
						key: 'name',
					},
					{
						title: '图片路径',
						slot: 'image',
						minWidth: 100
					},
					{
						title: '操作类型',
						key: 'link',
					},
					{
						title: '设备操作项分类',
						key: 'class_type',
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('Tag', {
									props: {
										color: 'success'
									}
								}, params.row.class_type ? (params.row.class_type == 1 ? '安全守护' :
									'设备控制') : '健康守护')
							])
						}
					},
					{
						title: '操作类型',
						key: 'action_type',
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('Tag', {
									props: {
										color: 'success'
									}
								}, params.row.action_type ? '方法' : '链接')
							])
						}
					},
					{
						title: '背景颜色',
						key: 'bg_color',
					},
					{
						title: '单位',
						key: 'company',
					},
					{
						title: '是否显示',
						key: 'status',
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('Tag', {
									props: {
										color: params.row.status ? 'success' : 'error'
									}
								}, params.row.status ? '显示' : '隐藏')
							])
						}
					},
					{
						title: '所属型号',
						slot: 'all_model_name',
						minWidth: 150
					},
					{
						title: '操作',
						slot: 'operation',
						minWidth: 150
					}
				],
				data: [],
				class_data: ['健康守护', '安全守护', '设备控制'],
				device_type: false, //打开关联参数
				type_data: {
					op_mo: '0',
					id: null,
					model_id: null
				},
				mo_op_data: {
					mo_disabled: true, //类型禁止开关
					mo_true: true, //类型多选开关
					op_disabled: false, //操作禁止开关
					op_true: false, //操作多选开关
				},
			}
		},
		mounted() {
			this.switch_init(this.t_name);
		},
		methods: {
			// 切换
			switch_init(e) {
				if (e == 'e_operation') {
					this.init()
				} else if (e == 'e_operation_parameters') {
					this.$refs.oper.init()
					this.$refs.oper.get_operation_type()
				} else if (e == 'e_parameter_operation') {
					this.$refs.paop.init()
				} else if (e === 'e_new_operation') {
          this.$refs.NewOperation.init();
        }
				this.t_name = e
			},

			init() {
				this.loading = true
				var _this = this
				this.requestApi('/adm/get_operation_data', {
					data: this.serData
				}).then(function(res) {
					_this.loading = false
					if (res.status == 200) {
						_this.total = res.data.total
						_this.data = res.data.data
						_this.model_data = res.model_data
						_this.op_data = res.op_data
						// _this.type_data = res.data.data['model_id']
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			//打开编辑设备操作框
			openModal(e = null) {
				if (e != null) {
					var obj = JSON.parse(JSON.stringify(this.data[e]))
					for (var i in obj['model_id']) {
						obj['model_id'][i] = parseInt(obj['model_id'][i]);
					}
					this.formData = obj
				} else {
					this.formData.id = null
					this.formData.name = null
					this.formData.image = null
					this.formData.action_type = 0
					this.formData.link = null
					this.formData.class_type = 0
					this.formData.bg_color = null
					this.formData.status = 1
					this.formData.company = null
				}
				this.device_modal = true
			},
			changePage(page) {
				this.serData.page = page;
				this.init()
			},
			changePageSize(size) {
				this.serData.page = 1;
				this.serData.pageSize = size;
				this.init()
			},
			//编辑设备操作
			save() {
				var _this = this;
				this.$refs.formValidate.validate((valid) => {
					if (valid) {
						this.requestApi('/adm/edit_operation', {
							data: this.formData,
						}).then(function(res) {
							if (res.status == 200) {
								_this.device_modal = false
								_this.alertSucc(res.msg)
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			},
			//删除设备操作
			del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定删除该设备操作吗？',
					onOk: (res) => {
						this.requestApi('/adm/del_operation', {
							id: id
						}).then(function(res) {
							if (res.status == 200) {
								_this.alertSucc(res.msg)
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			},
			//打开关联弹窗
			typeModal() {
				this.type_data['op_mo'] = '0'
				this.type_data['id'] = null
				this.type_data['model_id'] = null
				this.mo_op_data.op_disabled = false
				this.mo_op_data.mo_disabled = true
				this.mo_op_data.op_true = false
				this.mo_op_data.mo_true = true
				this.device_type = true
			},
			//选择
			choice(e) {
				var q = this.mo_op_data
				q.op_disabled = e == 1 ? true : false
				q.mo_disabled = e == 0 ? true : false
				q.op_true = e == 1 ? true : false
				q.mo_true = e == 0 ? true : false

				this.type_data['id'] = null
				this.type_data['model_id'] = null
			},
			//编辑关联类型
			edit_type() {
				var _this = this
				this.requestApi('/adm/edit_correlation_type', {
					data: this.type_data,
					type: this.t_name
				}).then(function(res) {
					if (res.status == 200) {
						_this.alertSucc(res.msg)
						_this.t_name == 'e_operation' ? _this.init() : _this.init_p()
						_this.device_type = false
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			// 获取关联数据
			select(e) {
				console.log(e)
				var q = this.mo_op_data
				var id = null
				if (this.type_data.model_id && e == 1) {
					id = this.type_data.model_id
					q.op_disabled = false
				} else if (this.type_data.id && e == 0) {
					id = this.type_data.id
					q.mo_disabled = false
				}

				var _this = this
				if (this.type_data.id || this.type_data.model_id) {
					this.requestApi('/adm/gat_correlation_data', {
						id: id,
						e: e,
					}).then(function(res) {
						if (res.status == 200) {
							if (e) {
								_this.type_data.id = res.data
							} else {
								_this.type_data.model_id = res.data
							}
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ser-form-centered {
		display: flex;
		align-items: center;
	}

	.margin-right {
		margin-right: 10px;
	}

	.color_css {
		color: #999;
	}

	.text-ellipsis {
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.frame_class {
		padding: 10px;
		background-color: #fff;
	}
</style>
